<template>
  <div class="search">
    <div class="search-box">
      <van-dropdown-menu active-color="#1989fa">
        <van-dropdown-item v-model="value" :options="option" />
      </van-dropdown-menu>
      <van-search v-model="message" @input="inputFocus" show-action placeholder="请输入小区或地址" @search="onSearch" autofocus>
        <template #action>
          <div @click="onSearch" v-if="flag">搜索</div>
          <div @click="onCancel" v-else>取消</div>
        </template>
      </van-search>
    </div>
    <div class="search-history">
      <div class="search-text">历史搜索</div>
      <div class="search-del" @click="removeHistory">
        <img src="../assets/images/del.png" alt="" />
      </div>
    </div>
    <div class="search-content" v-if="searchHistoryArr && searchHistoryArr.length > 0">
      <div class="search-item" v-for="item in searchHistoryArr" :key="item" @click="historySearch(item)">{{item}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Search",
  data() {
    return {
      message: "",
      value: 0,
      option: [
        { text: "新房", value: 0 },
        { text: "二手房", value: 1 },
      ],
      searchHistoryArr: [],
      flag: false,
    };
  },
  mounted() {
    let history = localStorage.getItem("searchHistory");
    if (history) {

      this.searchHistoryArr = history.split(",");
    }
  },
  methods: {
    onSearch() {
      if (this.message != '') {
        this.removeRepeat(this.message);
        localStorage.setItem("searchHistory", this.searchHistoryArr.toString());
        this.getSearch();
      } else {
        this.$toast("请输入搜索内容");
      }

    },
    getSearch() {
      //查询新房
      if (this.value == 0) {
        this.$router.push({
          path: "/buildingList",
          query: {
            id: this.value,
            message: this.message,
          },
        });
      } else if (this.value == 1) {
        this.$router.push({
          path: "/secondHouseList",
          query: {
            id: this.value,
            message: this.message,
          },
        });
      }
    },
    historySearch(item) {
      this.message = item;
      this.getSearch();
    },
    removeRepeat(value) {
      for (let i = 0; i < this.searchHistoryArr.length; i++) {
        if (value === this.searchHistoryArr[i]) {
          this.searchHistoryArr.splice(i, 1);
        }
      }
      if (this.searchHistoryArr.length < 10) {
        this.searchHistoryArr.unshift(value);
      } else {
        this.searchHistoryArr.pop();
        this.searchHistoryArr.unshift(value);
      }
    },
    removeHistory() {
      let history = localStorage.getItem("searchHistory");
      if (history) {
        localStorage.removeItem("searchHistory");
        this.searchHistoryArr = [];
      }
    },
    onCancel() {
      this.$router.push("/");
    },

    inputFocus(value) {
      this.message = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
      if (this.message != "") {
        this.flag = true;
      } else {
        this.flag = false;
      }
    }
  },
};
</script>
<style lang="less" scoped>
.search {
  .search-box {
    align-items: center;
    display: flex;
    height: 50px;
    box-sizing: border-box;
    padding: 10px 15px;
    background-color: #fff;

    /deep/ .van-dropdown-menu {
      width: 80px;

      .van-dropdown-menu__bar {
        box-shadow: unset;
        height: 30px;
        width: 80px;
        background-color: #f1f1f1;
        border-radius: 5px 0 0 5px;
        .van-dropdown-menu__item {
          height: 30px;
          box-sizing: border-box;
          .van-ellipsis {
            font-size: 15px;
          }
        }
        .van-dropdown-menu__title::after {
          border-color: transparent transparent #888 #888;
        }

        .van-dropdown-menu__title--active::after {
          border-color: transparent transparent currentColor currentColor;
        }
      }
    }

    /deep/ .van-search {
      padding: 0;
      height: 30px;
      flex: 1;
      position: relative;

      .van-search__action {
        padding-right: 0;
      }

      &::after {
        content: "";
        width: 1px;
        height: 20px;
        background-color: #ddd;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      .van-search__content {
        background-color: #f1f1f1;
        border-radius: 0 5px 5px 0;

        .van-cell {
          padding: 0;
          line-height: 30px;
        }
      }

      .van-search__action:active {
        background-color: #fff;
      }
      .van-icon-clear{
        padding: 0 18px 0 10px;
      }
    }
  }

  .search-history {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    border-bottom: 1px solid #ddd;
    padding: 0 15px;

    .search-text {
      color: #999;
      font-size: 15px;
    }

    .search-del {
     display: flex;
     align-content: center;
    justify-items: center;
      img {
        width: 15px;
        height: 15px;
        
      }
    }
  }
  .search-content {
    padding: 10px 15px;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    .search-item {
      padding: 0 15px;
      line-height: 32px;
      margin-right: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      background-color: #eee;
      color: #333;
      font-size: 14px;
      border-radius: 5px;
      max-width: 30%;
      word-break: break-all;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>